import React from "react"
import { graphql } from "gatsby"
import { Layout, LazyIframe, ContactForm } from "../components"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Contact = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <GatsbySeo
        title={frontmatter.seo_title}
        titleTemplate="CG Homeopathie - %s"
        description={frontmatter.seo_description}
      />
      <h1>{frontmatter.title}</h1>
      <div
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <div className="mt-8 max-w-full">
        <LazyIframe
          title="Locatie CG Homeopathie"
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2454.4011690003745!2d5.163904316081537!3d52.036006079725425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c66657fc6c5a91%3A0x847b6c61590825be!2sRandhoeve%20221%2C%203995%20GA%20Houten!5e0!3m2!1snl!2snl!4v1590492221875!5m2!1snl!2snl"
        />
      </div>
      <div className="mt-2 px-4">
        <h2>Contactformulier</h2>
        <ContactForm />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { slug: { eq: "/contactpagina" } }) {
      html
      frontmatter {
        title
        seo_title
        seo_description
      }
    }
  }
`

export default Contact
